import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { Event, trackEvent } from '~/ui/components/analytics';
import { useAsync } from '~/v1/hooks/useAsync';

import {
  FilterAction,
  FilterCategory,
  type FilterConfig,
  type FilterContextType,
  FilterGtmType,
  type FilterProps,
  type FilterQueryFn,
  FilterQueryProp,
  type FilterState,
  type FilterType,
  type SortType,
} from './filter.interface';
import { decodeFilters, formatState, toFilterState, toGtm, updateFilters } from './filter.utils';

interface UseFilterHook {
  api: FilterQueryFn;
  query?: string;
  config: FilterConfig;
  gtm?: FilterGtmType;
}

const DEFAULT_STATE: FilterState = {
  [FilterCategory.DEPARTMENTS]: [],
  [FilterCategory.YEARS]: [],
  [FilterCategory.AREAS]: [],
  [FilterCategory.IDEAS]: [],
  [FilterCategory.PAST_PROGRAM]: [],
  [FilterCategory.CONTENT_TYPES]: [],
  [FilterCategory.AMOUNT_RANGES]: [],
  [FilterCategory.YEAR_RANGE]: [],
  [FilterCategory.COUNTRY]: [],
  [FilterCategory.STATE]: [],
  [FilterCategory.TYPES]: [],
  [FilterCategory.FEATURES]: [],
  [FilterCategory.INITIATIVES]: [],
};

export const useFilterHook = ({
  api,
  query,
  config = {},
  gtm,
}: UseFilterHook): FilterContextType => {
  const { query: routeState } = useRouter();
  const prepopulated = toFilterState(decodeFilters(routeState));
  const { isLoading, async: asyncLoad } = useAsync();
  const { isLoading: isLoadingMore, async: asyncLoadMore } = useAsync();
  const [isFilterLayoutActive, setIsFilterLayoutActive] = useState(false);
  const [filterPills, setFilterPills] = useState<FilterType[]>(prepopulated.pills);
  const [filterData, setFilterData] = useState<object[]>([]);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [filters, setFilters] = useState<FilterState>({
    ...DEFAULT_STATE,
    ...prepopulated.state,
  });
  const [isResetting, setIsResetting] = useState(false);

  const getFilterEvents = (): {
    filterApplyEvent:
      | Event.DATABASE_FILTERS_APPLY
      | Event.FILTERS_APPLY
      | Event.NEWS_FILTERS_APPLY
      | Event.PEOPLE_FILTERS_APPLY
      | null;
    filterResetEvent:
      | Event.DATABASE_FILTERS_RESET
      | Event.FILTERS_RESET
      | Event.NEWS_FILTERS_RESET
      | Event.PEOPLE_FILTERS_RESET
      | null;
  } => {
    if (gtm === undefined) {
      return {
        filterApplyEvent: null,
        filterResetEvent: null,
      };
    }
    switch (gtm) {
      case FilterGtmType.DATABASE:
        return {
          filterApplyEvent: Event.DATABASE_FILTERS_APPLY,
          filterResetEvent: Event.DATABASE_FILTERS_RESET,
        };
      case FilterGtmType.GLOBAL:
        return {
          filterApplyEvent: Event.FILTERS_APPLY,
          filterResetEvent: Event.FILTERS_RESET,
        };
      case FilterGtmType.NEWS:
        return {
          filterApplyEvent: Event.NEWS_FILTERS_APPLY,
          filterResetEvent: Event.NEWS_FILTERS_RESET,
        };
      case FilterGtmType.PEOPLE:
        return {
          filterApplyEvent: Event.PEOPLE_FILTERS_APPLY,
          filterResetEvent: Event.PEOPLE_FILTERS_RESET,
        };
    }
  };

  const { filterApplyEvent, filterResetEvent } = getFilterEvents();

  const offsetIndex = useRef(0);
  const filterProps = useRef<FilterProps>({
    [FilterQueryProp.PARTIAL]: true,
    [FilterQueryProp.LIMIT]: config.limit,
    [FilterQueryProp.OFFSET]: 0,
    [FilterQueryProp.TERM]: query || '',
    [FilterQueryProp.SORT]: config.sort,
  });

  useEffect(() => {
    if (query) {
      filterProps.current[FilterQueryProp.TERM] = query;
      resetOffset();
      setFilterPills(prepopulated.pills);
      setFilters({
        ...DEFAULT_STATE,
        ...prepopulated.state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const applyFilters = async (filters: FilterState) => {
    const { data, count } = await api({
      ...filterProps.current,
      ...formatState(filters),
    });

    setFilters(filters);
    setFilterPills(Object.values(filters).flat());
    setFilterData(data);
    setFilterCount(count);
    setIsResetting(false);

    return Promise.resolve();
  };

  const loadMore = async (filters: FilterState, prevData: object[]) => {
    const { data, count } = await api({
      ...filterProps.current,
      ...formatState(filters),
    });

    setFilterData([...prevData, ...data]);
    setFilterCount(count);

    return Promise.resolve();
  };

  const resetOffset = () => {
    offsetIndex.current = 0;

    filterProps.current = {
      ...filterProps.current,
      [FilterQueryProp.OFFSET]: 0,
    };
  };

  const asyncApply = asyncLoad(applyFilters);
  const asyncApplyMore = asyncLoadMore(loadMore);

  const onFilterInit = (data: object[], count: number) => {
    setFilterData(data);
    setFilterCount(count);
  };

  const onSortUpdate = (sort: SortType) => {
    filterProps.current = {
      ...filterProps.current,
      [FilterQueryProp.SORT]: sort.id,
    };
  };

  const onFilterUpdate = (value: FilterType, action: FilterAction) =>
    setFilters(updateFilters([value, action], filters));

  const onFilterApply = () => {
    resetOffset();

    filterProps.current = {
      ...filterProps.current,
      [FilterQueryProp.PARTIAL]: true,
      [FilterQueryProp.LIMIT]: config.initialLimit,
      [FilterQueryProp.OFFSET]: 0,
    };

    filterApplyEvent && trackEvent(filterApplyEvent, toGtm(filters));

    setIsFilterLayoutActive(false);

    return asyncApply(filters);
  };

  const onFilterReset = () => {
    resetOffset();
    setIsFilterLayoutActive(false);
    setIsResetting(true);
    filterResetEvent && trackEvent(filterResetEvent);

    return asyncApply(DEFAULT_STATE);
  };

  const onFilterAll = () => {
    filterProps.current = {
      ...filterProps.current,
      [FilterQueryProp.PARTIAL]: false,
      [FilterQueryProp.LIMIT]: 0,
    };

    return asyncApply(filters);
  };

  const onFilterRemove = (filter: FilterType) => {
    resetOffset();

    const updated = updateFilters([filter, FilterAction.REMOVE], filters);

    return asyncApply(updated);
  };

  const onFilterLoadMore = () => {
    filterProps.current[FilterQueryProp.LIMIT] = config.limit;
    const updatedOffset =
      filterProps.current[FilterQueryProp.LIMIT] * offsetIndex.current + config.initialLimit;

    filterProps.current[FilterQueryProp.OFFSET] = updatedOffset;
    offsetIndex.current = offsetIndex.current + 1;

    return asyncApplyMore(filters, filterData);
  };

  const onFilterPropagate = (
    filter: FilterType,
    action: FilterAction,
    category: FilterCategory,
  ) => {
    setFilters(updateFilters([filter, action], filters, category));
  };

  const isFilterActive = (filter: FilterType) =>
    filters[filter.category].some(({ id }) => id === filter.id);

  return {
    filterData,
    filterCount,
    filterPills,
    filterProps: filterProps.current,
    isFilterActive,
    isFilterLayoutActive,
    isLoading,
    isLoadingMore,
    isResetting,
    setIsFilterLayoutActive,
    onSortUpdate,
    onFilterUpdate,
    onFilterPropagate,
    onFilterInit,
    onFilterAll,
    onFilterApply,
    onFilterRemove,
    onFilterReset,
    onFilterLoadMore,
  };
};
