import { useEffect } from 'react';

import { FilterBar, type FilterBarProps } from './bar/bar';
import { useFilterContext } from './filter.context';
import { FilterPills } from './pills/pills';

interface FilterProps extends FilterBarProps {
  children?: React.ReactNode;
  className?: string;
  data: object[];
  total: number;
  scrollIntoView?: boolean;
}

export const Filter: React.FC<FilterProps> = ({
  children,
  className,
  data,
  total,
  title,
  barTitle,
  label,
  scrollIntoView,
}) => {
  const { onFilterInit } = useFilterContext();

  useEffect(() => {
    onFilterInit(data, total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, total]);

  return (
    <>
      {title || barTitle ? (
        <FilterBar
          scrollIntoView={scrollIntoView}
          label={label}
          title={title}
          barTitle={barTitle}
          className={className}
        >
          <FilterPills />
        </FilterBar>
      ) : null}

      {children}
    </>
  );
};
