import { Fragment, useEffect, useRef } from 'react';

import cx from 'classnames';

import { Column, Grid } from '~/ui/components/grid';
import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Breakpoint } from '~/ui/styles/grid';
import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonSize, ButtonType } from '~/v1/components/button/button.interface';
import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { useSticky } from '~/v1/hooks/useSticky';

import styles from './bar.module.scss';
import { useFilterContext } from '../filter.context';
import { FilterQueryProp } from '../filter.interface';

export interface FilterBarProps {
  className?: string;
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  barTitle?: React.ReactNode;
  label?: string;
  scrollIntoView?: boolean;
}

export const FilterBar: React.FC<FilterBarProps> = ({
  className,
  children,
  title,
  barTitle,
  label,
  scrollIntoView = true,
}) => {
  const { filterPills, filterProps, setIsFilterLayoutActive } = useFilterContext();
  const { ref, isSticky } = useSticky<HTMLDivElement>();
  const bp = useBreakpoint();
  const refreshRef = useRef<HTMLDivElement>(null);

  const onOpenFilters = () => setIsFilterLayoutActive(true);

  const pillsLabel = filterPills.length ? `(${filterPills.length})` : '';
  const actionLabel = isSticky
    ? bp === Breakpoint.SM
      ? pillsLabel
      : `${label || 'Filter'} ${pillsLabel}`
    : label || 'Filter';

  const shouldScroll = filterProps[FilterQueryProp.PARTIAL];

  useEffect(() => {
    if (refreshRef.current && scrollIntoView) {
      refreshRef.current.scrollIntoView();
    }
  }, [filterPills, shouldScroll, scrollIntoView]);

  return (
    <Fragment>
      <div ref={refreshRef} />
      <div
        className={cx(styles.bar, 'bar', className, {
          [styles.isSticky]: isSticky,
        })}
        ref={ref}
      >
        <Grid>
          <Column offsetLeft={{ lg: 1 }} sm={5} md={7} lg={7}>
            <div className={styles.header}>
              <h3
                className={cx(styles.title, 'filterTitle', 'modulesTitleTypography', {
                  [styles.active]: !isSticky,
                })}
              >
                {title}
              </h3>
              <Button
                className={cx(styles.action, {
                  [styles.round]: !actionLabel,
                })}
                type={ButtonType.Secondary}
                size={ButtonSize.Tiny}
                mode={ButtonMode.Light}
                onClick={onOpenFilters}
                ariaLabel={actionLabel}
              >
                <span>{actionLabel}</span>
                <Icon className={styles.icon} type={IconType.Filter} />
              </Button>
            </div>
          </Column>
        </Grid>
        <div
          className={cx(styles.scrollBar, {
            [styles.isSticky]: isSticky,
          })}
        >
          <Grid>
            <Column offsetLeft={{ lg: 1 }} sm={5} md={7} lg={7}>
              <div className={(styles.scrollHeader, styles.header)}>
                <p className={cx(styles.barTitle, 'paragraphTypography')}>{barTitle}</p>
                <Button
                  className={cx(styles.action, {
                    [styles.round]: !actionLabel,
                  })}
                  disabled={!isSticky}
                  type={ButtonType.Secondary}
                  size={ButtonSize.Tiny}
                  mode={ButtonMode.Light}
                  onClick={onOpenFilters}
                  ariaLabel={!!actionLabel ? actionLabel : ''}
                >
                  {!!actionLabel && <span>{actionLabel}</span>}
                  <Icon className={styles.icon} type={IconType.Filter} />
                </Button>
              </div>
            </Column>
          </Grid>
        </div>
      </div>

      {children}
    </Fragment>
  );
};
