import { NewsSort, SearchSort } from '~/v1/_types/globalTypes';

import { type FilterConfig, FilterQueryProp } from './filter.interface';

export const FILTER_CONFIG: FilterConfig = {
  EVENTS: {
    [FilterQueryProp.INITIAL_LIMIT]: 4,
    [FilterQueryProp.LIMIT]: 50,
  },
  NEWS: {
    [FilterQueryProp.INITIAL_LIMIT]: 5,
    [FilterQueryProp.LIMIT]: 50,
    [FilterQueryProp.SORT]: NewsSort.NEWEST,
  },
  STAFF: {
    [FilterQueryProp.INITIAL_LIMIT]: 16,
    [FilterQueryProp.LIMIT]: 0,
  },
  SEARCH: {
    [FilterQueryProp.INITIAL_LIMIT]: 50,
    [FilterQueryProp.LIMIT]: 50,
    [FilterQueryProp.SORT]: SearchSort.MOST_RELEVANT,
  },
};
