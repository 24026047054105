import { Column, Grid } from '~/ui/components/grid';
import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonSize, ButtonType } from '~/v1/components/button/button.interface';
import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';

import styles from './pills.module.scss';
import { useFilterContext } from '../filter.context';
import { type FilterType } from '../filter.interface';

export const FilterPills: React.FC = () => {
  const { filterPills, onFilterRemove, onFilterReset } = useFilterContext();

  const onRemove = (filter: FilterType) => () => onFilterRemove(filter);

  return filterPills.length ? (
    <div className={styles.pills}>
      <Grid>
        <Column offsetLeft={{ lg: 1 }} sm={5} md={7} lg={7}>
          <div className={styles.wrapper}>
            {filterPills.map(filter => (
              <Button
                isAsync
                key={filter.id}
                type={ButtonType.Theme}
                size={ButtonSize.Tiny}
                onClick={onRemove(filter)}
                ariaLabel={filter.name}
              >
                {filter.name} <Icon type={IconType.Close} className={styles.icon} />
              </Button>
            ))}
            {filterPills.length > 2 && (
              <Button
                isAsync
                type={ButtonType.Secondary}
                size={ButtonSize.Tiny}
                onClick={onFilterReset}
                mode={ButtonMode.Light}
                ariaLabel="Clear all"
              >
                Clear all <Icon type={IconType.Close} className={styles.icon} />
              </Button>
            )}
          </div>
        </Column>
      </Grid>
    </div>
  ) : null;
};
