import { FilterContextProvider } from './filter.context';
import { useFilterHook } from './filter.hook';
import { type FilterConfig, type FilterGtmType, type FilterQueryFn } from './filter.interface';

interface FilterManagerProps {
  children: React.ReactNode;
  api: FilterQueryFn;
  query?: string;
  config: FilterConfig;
  gtm?: FilterGtmType;
}

export const FilterManager: React.FC<FilterManagerProps> = ({
  children,
  api,
  query,
  config,
  gtm,
}) => {
  const value = useFilterHook({ api, query, config, gtm });

  return <FilterContextProvider value={value}>{children}</FilterContextProvider>;
};
